<template>
  <v-main class="cppsbg">
    <v-app-bar
      class="ukpl-main-navigation"
      v-if="currentUser"
      dense
      dark
      elevate-on-scroll
    >
      <div class="ukpl-main-navigation-container">
        <div class="navigation-left-wrap">
          <a href="https://www.ukpl.co.uk">
            <v-img
              src="@/assets/ukpl-logo-new.png"
              alt="UKPL logo"
              contain
              class="ukpl-nav-logo"
            />
          </a>

          <v-btn v-if="isChangePassword" rounded dark @click="savepassword"
            >Save</v-btn
          >
          <v-btn
            class="hidden-sm-and-down"
            v-if="!isChangePassword"
            rounded
            dark
            @click="changePassword"
            >Change Password</v-btn
          >
        </div>
        <!-- <div class="navigation-links-wrap"> -->
        <v-spacer></v-spacer>

        <v-toolbar-items class="hidden-sm-and-down navigation-links">
          <v-btn v-if="isAdmin" depressed to="/admin">admin</v-btn>
          <v-btn depressed to="/team"
            ><v-icon class="mr-2">fa-users</v-icon>Teams</v-btn
          >
          <v-btn v-if="currentCaptain" depressed to="/roster"
            ><v-icon class="mr-2">fa-list</v-icon>Roster</v-btn
          >
          <v-btn depressed to="/profile"
            ><v-icon class="mr-2">fa-user</v-icon>Profile</v-btn
          >
          <v-btn depressed to="/disclaimer"
            ><v-icon class="mr-2">fa-file-signature</v-icon>Disclaimer</v-btn
          >
          <v-btn depressed to="/idcard"
            ><v-icon class="mr-2">far fa-id-card</v-icon>ID Card</v-btn
          >
          <v-btn depressed @click.native="logOut"
            ><v-icon class="mr-2">fa-sign-out-alt</v-icon>LogOut</v-btn
          >
        </v-toolbar-items>
      </div>

      <v-toolbar-items class="hidden-md-and-up">
        <v-app-bar-nav-icon @click="drawer = true"></v-app-bar-nav-icon>
      </v-toolbar-items>
    </v-app-bar>

    <v-navigation-drawer v-model="drawer" absolute temporary>
      <v-list nav dense>
        <v-list-item-group>
          <v-list-item
            v-if="!isChangePassword"
            @click.native="changePassword"
            key="changepassword"
          >
            <v-list-item-icon><v-icon>fa-key</v-icon></v-list-item-icon>
            <v-list-item-title>Change Password</v-list-item-title>
          </v-list-item>
          <v-list-item v-if="isAdmin" key="admin" to="/admin">
            <v-list-item-title>Admin</v-list-item-title>
          </v-list-item>
          <v-list-item key="team" to="/team">
            <v-list-item-icon><v-icon>fa-users</v-icon></v-list-item-icon>
            <v-list-item-title>Teams</v-list-item-title>
          </v-list-item>
          <v-list-item v-if="currentCaptain" key="roster" to="/roster">
            <v-list-item-icon><v-icon>fa-list</v-icon></v-list-item-icon>
            <v-list-item-title>Roster</v-list-item-title>
          </v-list-item>
          <v-list-item key="profile" to="/profile">
            <v-list-item-icon><v-icon>fa-user</v-icon></v-list-item-icon>
            <v-list-item-title>Profile</v-list-item-title>
          </v-list-item>
          <v-list-item key="disclaimer" to="/disclaimer">
            <v-list-item-icon
              ><v-icon>fa-file-signature</v-icon></v-list-item-icon
            >
            <v-list-item-title>Disclaimer</v-list-item-title>
          </v-list-item>
          <v-list-item key="id-card" to="/idcard">
            <v-list-item-icon><v-icon>far fa-id-card</v-icon></v-list-item-icon>
            <v-list-item-title>ID Card</v-list-item-title>
          </v-list-item>
          <v-list-item @click.native="logOut" key="icon">
            <v-list-item-icon
              ><v-icon>fa-sign-out-alt</v-icon></v-list-item-icon
            >
            <v-list-item-title>LogOut</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <!-- Main Form -->
    <v-sheet class="ukpl-blue-card-container" height="100%">
      <v-container v-if="loading">
        <v-row class="fill-height" align-content="center" justify="center">
          <v-col class="text-subtitle-1 text-center" cols="12">
            Loading Profile
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-progress-linear
              color="blue"
              indeterminate
              rounded
              height="6"
            ></v-progress-linear>
          </v-col>
        </v-row>
      </v-container>
      <v-container fluid height="100%" v-if="!loading">
        <v-row
          v-if="changePassword"
          justify="center"
          align="center"
          class="row"
        >
          <v-col cols="12" sm="12" md="12" xs="12" class="text-center">
            <v-form ref="passwordform" class="mx-2">
              <v-row v-if="isChangePassword">
                <v-col cols="6">
                  <v-text-field
                    id="registerpassword"
                    v-model="userDetails.password"
                    :rules="[rules.required, rules.min]"
                    prepend-icon="mdi-account"
                    label="Password"
                    type="password"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    id="verifypassword"
                    v-model="verifypassword"
                    :rules="[rules.required, rules.min]"
                    :error-messages="passwordConfirmation()"
                    label="Confirm Password"
                    type="password"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-col>
        </v-row>

        <v-form ref="form" class="mx-2" lazy-validation>
          <v-row class="row">
            <v-col>
              <div>
                <v-row
                  v-if="userDetails.cardpurchasecount > 0"
                  no-gutters
                  justify="center"
                  align="center"
                >
                  <p>
                    You have purchased your ID card, therefore your photo is now
                    locked for the year.
                  </p>
                </v-row>
                <v-row no-gutters justify="center" align="center">
                  <v-col cols="1"></v-col>
                  <v-col cols="10">
                    <v-file-input
                      :disabled="userDetails.cardpurchasecount > 0"
                      :rules="mandatoryRules"
                      v-model="fileinput"
                      label="Select Your ID Card Photo"
                      accept="image/*"
                      dense
                      :clearable="false"
                      @change="selectImage"
                      truncate-length="50"
                    ></v-file-input>
                  </v-col>
                  <v-col cols="1"></v-col>
                </v-row>
                <!-- Photo info section -->
                <v-row>
                  <v-col cols="1"></v-col>
                  <v-col cols="8">
                    <v-expansion-panels v-model="photoinfo">
                      <v-expansion-panel :key="0">
                        <v-expansion-panel-header>
                          Photograph Information
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <h2>You are uploading in ID CARD PHOTO!</h2>
                          <h2>
                            Unsuitable ID Card Photos will not be approved
                          </h2>
                          <p>
                            Your picture must be suitable for making an ID Card
                            and should look as close to a passport photo as
                            possible.
                          </p>
                          <p>
                            Please follow the below guidelines to ensure that
                            your picture is suitable and use the template
                            provided as a guide.
                          </p>
                          <ul>
                            <li>
                              Remove anything above the neck except for
                              non-tinted, corrective eyewear so that your face
                              is clearly visible.
                            </li>
                            <li>
                              Take your photo in a well lit location, or use a
                              flash.
                            </li>
                            <li>
                              Make sure your face takes up the main portion of
                              the photo.
                            </li>
                            <li>Look directly at the camera.</li>
                            <li>
                              use the highest resolution you can on your camera
                              and make sure your image is not rotated.
                            </li>
                            <li>
                              If your image shows sideways here, please rotate
                              on your device and reupload so it is upright as in
                              the example picture
                            </li>
                            <li>
                              DO NOT use any filters on the photograph (soften,
                              blur etc.)
                            </li>
                          </ul>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </v-col>
                  <!-- <v-col cols="1"></v-col> -->
                  <v-col cols="2">
                    <div v-if="previewImage">
                      <div>
                        <v-img
                          class="preview my-3"
                          :src="previewImage"
                          alt=""
                        />
                      </div>
                    </div>
                    <div v-else>
                      <v-img src="@/assets/passport-photo-template.jpg" />
                    </div>
                  </v-col>
                  <v-col cols="1"></v-col>
                </v-row>
                <!-- End of Photo Information  -->
                <v-row v-if="photoinfo === 0">
                  <v-col>
                    <v-alert
                      type="error"
                      ><strong>Make sure the file size of your photo is less than
                              3MB. If using a high end modern smartphone, your
                              camera resolution on maximum may generate photos
                              which are too large. Please check and reduce your
                              camera resolution on your phone if required.</strong></v-alert>
                  </v-col>
                </v-row>

                <v-row v-if="photoinfo === 0">
                  <v-col>
                    <v-alert
                      border="bottom"
                      type="warning"
                      colored-border
                      color="red"
                      >By uploading your photo you confirm that this photo is a
                      true likeness of yourself and not another
                      individual.</v-alert
                    >
                  </v-col>
                </v-row>

                <v-snackbar
                  :timeout="2000"
                  v-model="photomessagestatus"
                  color="blue accent-4"
                  rounded="pill"
                  elevation="24"
                >
                  {{ photomessage }}
                </v-snackbar>

                <v-card v-if="imageInfos.length > 0" class="mx-auto">
                  <v-list>
                    <v-list-item-group color="primary">
                      <v-list-item
                        v-for="(image, index) in imageInfos"
                        :key="index"
                      >
                        <a :href="image.url">{{ image.name }}</a>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-card>
              </div>
            </v-col>
          </v-row>

          <v-row justify="center" align="center" class="row">
            <v-col cols="12" sm="12" md="12" xs="12" class="text-center">
              <div class="profile-heading-wrap">
                <h2 class="profile-heading">Personal Details</h2>
              </div>
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    v-model="userDetails.username"
                    :rules="mandatoryRules"
                    prepend-icon="mdi-account"
                    label="UserName"
                    dense
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    v-model="userDetails.firstname"
                    :rules="mandatoryRules"
                    prepend-icon="mdi-account"
                    label="First Name"
                    dense
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="userDetails.lastname"
                    :rules="mandatoryRules"
                    label="Last Name"
                    dense
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="userDetails.jersey"
                    label="Jersey #"
                    :rules="jerseyLimit"
                    prepend-icon="mdi-pound-box"
                    dense
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-menu
                    ref="dobmenu"
                    v-model="dobmenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="userDetails.dateOfBirth"
                        label="Date Of Birth"
                        prepend-icon="mdi-calendar"
                        readonly
                        :rules="mandatoryRules"
                        v-bind="attrs"
                        v-on="on"
                        dense
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="userDetails.dateOfBirth"
                      :active-picker.sync="activePicker"
                      :max="
                        new Date(
                          Date.now() - new Date().getTimezoneOffset() * 60000
                        )
                          .toISOString()
                          .substr(0, 10)
                      "
                      min="1940-01-01"
                      @change="savedob"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="6">
                  <v-select
                    v-model="userDetails.gender"
                    :items="gender"
                    :rules="mandatoryRules"
                    prepend-icon="mdi-gender-transgender"
                    label="Gender"
                    dense
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    v-model="userDetails.email"
                    :rules="emailRules"
                    prepend-icon="mdi-at"
                    label="Email"
                    dense
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="userDetails.contactNumber"
                    :rules="mandatoryRules"
                    prepend-icon="mdi-cellphone"
                    label="Contact Number"
                    dense
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    v-model="userDetails.addressline1"
                    :rules="mandatoryRules"
                    label="Address Line 1"
                    prepend-icon="mdi-home"
                    dense
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="userDetails.addressline2"
                    label="Address Line 2"
                    prepend-icon="mdi-home"
                    dense
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <!-- <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="userDetails.addressline2"
                    label="Address Line 2"
                    dense
                  >
                  </v-text-field>
                </v-col>
              </v-row> -->
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    v-model="userDetails.town"
                    :rules="mandatoryRules"
                    label="Town"
                    prepend-icon="mdi-home"
                    dense
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="userDetails.county"
                    :rules="mandatoryRules"
                    label="County"
                    prepend-icon="mdi-home"
                    dense
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <!-- <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="userDetails.county"
                    :rules="mandatoryRules"
                    label="County"
                    dense
                  >
                  </v-text-field>
                </v-col>
              </v-row> -->
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    v-model="userDetails.postcode"
                    :rules="mandatoryRules"
                    label="Postcode"
                    prepend-icon="mdi-home"
                    dense
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <div class="profile-heading-wrap">
                    <h2 class="profile-heading">UKPSF Number</h2>
                  </div>
                  <p v-if="loadingAPI">Validating with UKPSF</p>
                  <v-text-field
                    v-model="userDetails.ukpsf"
                    :rules="[rules.required, rules.min, rules.max]"
                    :error-messages="ukpsfErrors"
                    prepend-icon="mdi-card-account-details"
                    label="UKPSF Number"
                    dense
                  >
                    <!--                    
                        <v-text-field
                          v-model="userDetails.ukpsf"
                          @blur="searchTimeOut()"
                          :rules="mandatoryRules"
                          :error-messages="ukpsfErrors"
                          prepend-icon="mdi-card-account-details"
                          label="UKPSF Number"
                      > -->
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <div class="profile-heading-wrap">
                    <h2 class="profile-heading">Team Info</h2>
                  </div>
                  <v-checkbox
                    v-model="teamcaptain"
                    label="Are you the Team Captain?"
                    dense
                  ></v-checkbox>
                  <v-alert
        shaped
        prominent
        type="error"
        v-if="teamcaptain"
      >
        <strong>PLEASE ENSURE THAT YOU HAVE ONLY TICKED THIS IF YOU ARE THE TEAM CAPTAIN.  IF YOU ARE NOT, UNTICK NOW!</strong>
      </v-alert>                
    </v-col>
              </v-row>
              <v-row justify="center" align="center" class="row">
                <v-col cols="6" sm="6" md="6" xs="6 " class="text-center">
                  <v-btn
                    class="red-btn-primary"
                    v-if="!isChangePassword"
                    rounded
                    dark
                    @click="save"
                    >Next</v-btn
                  >
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </v-sheet>
    <v-snackbar
      :timeout="2000"
      v-model="savefail"
      color="red accent-4"
      rounded="pill"
      elevation="24"
    >
      {{ failmessage }}
    </v-snackbar>
    <v-snackbar
      :timeout="2000"
      v-model="savesuccess"
      color="green accent-4"
      rounded="pill"
      elevation="24"
    >
      Details Saved.
    </v-snackbar>
    <v-snackbar
      :timeout="3000"
      v-model="photosave"
      color="green accent-4"
      rounded="pill"
      elevation="24"
    >
      Uploading Photo, Please wait for success message.
    </v-snackbar>
  </v-main>
</template>

<script>
import UserService from "../services/user.service";
import UserDetails from "../model/userdetails";

import UploadService from "../services/uploadfiles.service";
import UtilService from "../services/util.service";

export default {
  name: "Profile",
  data: () => ({
    bucket: "https://assets.ukpl.co.uk/",
    photobase: "images/",
    thumbbase: "thumbs/images/",
    loading: true,
    currentCaptain: false,
    photomessage: "",
    photomessagestatus: false,
    fileinput: undefined,
    teamcaptain: false,
    gender: ["Male", "Female", "Transgender", "Prefer Not To Say"],
    // divisionsteams: [],
    // selectdiv: "1",
    // selectteam: "",
    // selectdivteam: "",
    // backupdiv: undefined,
    // backupteam: undefined,
    drawer: false,
    userDetails: new UserDetails(),
    failmessage: "",
    changepassword: false,
    savefail: false,
    savesuccess: false,
    emailRules: [],
    mandatoryRules: [],
    imageRules: [],
    loadingAPI: false,
    timer: 0,
    dobmenu: false,
    activePicker: null,
    verifypassword: "",
    password: "",
    //selectdivlastyear: "",
    //didnotplaylastyear: false,
    currentyear: 0,
    photodata: undefined,
    rules: {
      required: (value) => !!value || "Field is Required.",
      required2: (value) => !!value.divId && !!value.teamId || "please select a value.",
      min: (v) =>
        (v != undefined && v.length >= 8) || "Must be at least 8 characters",
      max: (v) =>
        (v != undefined && v.length <= 9) || "Must be at most 9 characters",
      emailMatch: (v) => /.+@.+/.test(v) || "E-mail must be valid",
    },
    ukpsfErrors: [],

    currentImage: undefined,
    previewImage: undefined,
    message: "",
    imageInfos: [],
    photoinfo: 0,
    photosave: false,
  }),
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    isChangePassword() {
      return this.changepassword;
    },
    fileSaved() {
      if (this.userDetails.file != undefined) {
        return true;
      } else {
        return false;
      }
    },
    isAdmin() {
      if (this.currentUser && this.currentUser.roles) {
        return this.currentUser.roles.includes("ROLE_ADMIN");
      }
      return false;
    },
  },
  methods: {
    isCaptain() {
      UserService.isTeamCaptain().then(
        (response) => {
          if (response.data) {
            this.currentCaptain = true;
          } else {
            this.currentCaptain = false;
          }
        },
        (error) => {
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
    calcPreviewImage(photoId, photoType) {
      if (photoId != undefined && photoType != undefined) {
        let filename = this.bucket + this.thumbbase + photoId;
        if (photoType === "image/jpeg") {
          filename = filename + ".jpg";
        } else if (photoType === "image/png") {
          filename = filename + ".png";
        } else {
          filename = "";
        }
        return filename;
      } else {
        return "";
      }
    },
    selectImage(image) {
      this.photosave = true;
      this.currentImage = image;
      this.upload();
      this.photomessage = "";
      this.photoinfo = undefined;
    },
    upload() {
      if (!this.currentImage) {
        this.photomessage = "Please select an Image!";
        return;
      }
      if (this.fileinput.size > 3000000) {
        this.photomessage = "Photo larger than 3MB, please try a smaller image";
      }
      UploadService.upload(this.currentImage).then(
        (response) => {
          // response should contain FileDB with id so we can create URL
          // TODO: need to process response and set Image URL here.
          this.photodata = response.data.file;
          this.photomessage = response.data.message;
          setTimeout(
            () =>
              (this.previewImage = this.calcPreviewImage(
                response.data.file.id,
                response.data.file.type
              )),
            2000
          );
        },
        (error) => {
          this.photomessage = "Could not upload the image! " + error;
          this.currentImage = undefined;
        }
      );
    },
    savedob() {
      this.dobmenu = false;
    },
    imageuploaded(file) {
      this.userDetails.file = { data: file };
    },
    initCurrentYear() {
      UtilService.getCurrentYear().then(
        (response) => {
          this.currentyear = response.data.year;
        },
        (error) => {
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
    initializeUser() {
      UserService.getUserDetails().then(
        (response) => {
          console.log("response.data:" + response.data);

          this.userDetails.username = response.data.username;
          this.userDetails.email = response.data.email;
          this.userDetails.password = response.data.password;
          this.userDetails.firstname = response.data.firstname;
          this.userDetails.lastname = response.data.lastname;
          this.userDetails.gender = response.data.gender;
          this.userDetails.dateOfBirth = response.data.dateOfBirth;
          this.userDetails.contactNumber = response.data.contactNumber;
          this.userDetails.addressline1 = response.data.addressline1;
          this.userDetails.addressline2 = response.data.addressline2;
          this.userDetails.town = response.data.town;
          this.userDetails.county = response.data.county;
          this.userDetails.postcode = response.data.postcode;
          this.userDetails.file = response.data.file;
          this.userDetails.ukpsf = response.data.ukpsf;
          this.userDetails.teamcaptain = response.data.teamcaptain;
          this.teamcaptain = response.data.teamcaptain;
          this.userDetails.cardpurchasecount = response.data.cardpurchasecount;
          this.userDetails.cardtransactions = response.data.cardtransactions;
          //this.userDetails.lastyearsdivision = response.data.lastyearsdivision;
          this.userDetails.disclaimer = response.data.disclaimer;
          this.userDetails.disclaimerdate = response.data.disclaimerdate;
          this.userDetails.jersey = response.data.jerseyNumber;

          if (this.userDetails.file != null) {
            this.fileinput = this.userDetails.file;
            this.photoinfo = "";
            this.previewImage = this.calcPreviewImage(
              response.data.file.id,
              response.data.file.type
            );
          }

          this.loading = false;
        },
        (error) => {
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.loading = false;
        }
      );
      this.isCaptain();
    },
    passwordConfirmation() {
      return this.userDetails.password === this.verifypassword
        ? ""
        : "Passwords must match";
    },
    logOut() {
      console.log("logging out");
      this.$store.dispatch("auth/logout");
      this.$router.push("/home");
    },
    async callAPI() {
      UserService.checkUKPSF(this.userDetails.ukpsf).then(
        (data) => {
          console.log(data);
          this.loadingAPI = false;
        },
        (error) => {
          console.log(error);
          this.loadingAPI = false;
          this.ukpsfErrors = ["User not found"];
        }
      );
    },
    searchTimeOut() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.loadingAPI = true;
      this.ukpsfErrors = [];
      this.timer = setTimeout(() => {
        this.callAPI();
      }, 800);
    },
    changePassword() {
      this.changepassword = true;
      this.userDetails.password = "";
      return this.changepassword;
    },
    save() {
      this.savesuccess = false;
      this.savefail = false;
      this.failmessage = "";
      this.userDetails.teamcaptain = this.teamcaptain;

      if (!this.$refs.form.validate()) {
        return false;
      }

      UserService.save(this.userDetails).then(
        (response) => {
          this.message = response.message;
          this.registersuccess = true;
          this.savesuccess = true;
        },
        (error) => {
          this.failmessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.savefail = true;
        }
      );
    },
    savepassword() {
      if (
        !this.$refs.passwordform.validate() ||
        this.passwordConfirmation() != ""
      ) {
        return false;
      }
      UserService.changePassword(this.userDetails).then(
        (response) => {
          this.message = response.message;
          this.registersuccess = true;
          this.savesuccess = true;
        },
        (error) => {
          this.failmessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.savefail = true;
        }
      );
      this.changepassword = false;
    },
  },
  created() {
    this.initCurrentYear();
    this.initializeUser();
  },
  mounted() {
    // this.selectteam = this.backupteam;
    // this.selectdiv = this.thisYearDivision;
    UploadService.getFiles().then((response) => {
      this.imageInfos = response.data;
    });
    if (!this.currentUser) {
      this.$router.push("/home");
    }
    this.mandatoryRules = [(v) => !!v || "Field is required"];
    this.jerseyLimit = [(v) => /^[0-9]{1,3}$/.test(v) || "Jersey Number must be a number and max 3 digits."];
    this.emailRules = [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+/.test(v) || "E-mail must be valid",
    ];
  },
  watch: {
    dobmenu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
    savesuccess: function () {
      if (this.savesuccess === false) {
        this.$router.push("/disclaimer");
      }
    },
    photomessage: function () {
      if (this.photomessage != "") {
        this.photomessagestatus = true;
      }
    },
  },
};
</script>
<style>
.preview {
  max-width: 200px;
}

.text-left {
  text-align: left;
}

.profile-heading-wrap {
  width: 100%;
  text-align: left;
  padding: 20px 0px;
}

.profile-heading {
  color: #fff;
  text-align: left;
  font-size: 2rem;
}
</style>
